// cartReducer.js
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: [],
    cartItemsCount: 0,
  },
  reducers: {
    setCartItems: (state, action) => {
        state.cartItems = action.payload; // Update cartItems in the store
        state.cartItemsCount = action.payload.length;
    },

    setCartItemsCount: (state, action) => {
        state.cartItemsCount = action.payload; // Update cartItemsCount in the store
      },
  
  },
});

export const { setCartItemsCount,setCartItems } = cartSlice.actions;

export default cartSlice.reducer;
