import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AddToCart from './AddToCart';

const LatestProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v3/catalog/products?include=images,options&limit=8&sort=id&direction=desc`
          );
      setProducts(response.data.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  return (
    
    <div className="container-fluid pt-5">
        <div className="text-center mb-4">
            <h2 className="section-title px-5"><span className="px-2">Just Arrived</span></h2>
        </div>
        <div className="row px-xl-5 pb-3">
            {products.map((product) => (
                    <div className="col-lg-3 col-md-6 col-sm-12 pb-1" key={product.id}>
                    <div className="card product-item border-0 mb-4">
                        <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                        {product.images.length > 0 ? (
                          <img className="img-fluid w-100" src={product.images[0].url_standard} alt="" key={product.images[0].id} />
                        ) : (
                          <img className="img-fluid w-100" src="/assets/img/default-product.png" alt="Default Image" />
                        )}
                        </div>
                        <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                            <h6 className="text-truncate mb-3">{product.name}</h6>
                            <div className="d-flex justify-content-center">
                                <h6>${product.calculated_price}</h6><h6 className="text-muted ml-2"><del>${product.price}</del></h6>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-between bg-light border">
                            <a href={`/product-detail/${product.id}/${product.sku}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</a>
                            <AddToCart product={product} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default LatestProducts;