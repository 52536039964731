import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Checkout = () => {
    const cartDataString = localStorage.getItem('cartData');
    const cartData = JSON.parse(cartDataString);

    if(cartData === null){
      navigate('/cart-listing');
    }
    const cartItems = cartData.line_items.physical_items;
    const cartCount = localStorage.getItem('cartCount');
    const cartId = localStorage.getItem('cartId');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
   
    useEffect(() => {
        if (cartCount === 0) {
          navigate('/cart-listing');
        }
      }, [cartCount, navigate]);
    
    const [billingAddress, setBillingAddress] = useState({
        billing_first_name:'',
        billing_last_name:'',
        billing_email:'',
        billing_phone:'',
        billing_address1: '',
        billing_address2: '',
        billing_country: '',
        billing_city: '',
        billing_state: '',
        billing_postal_code:''
      });
      const [shippingAddress, setShippingAddress] = useState({
        shipping_first_name:'',
        shipping_last_name:'',
        shipping_email:'',
        shipping_phone:'',
        shipping_address1: '',
        shipping_address2: '',
        shipping_country: '',
        shipping_city: '',
        shipping_state: '',
        shipping_postal_code:''
      });
    
      const handleBillingChange = (e) => {
        const { name, value } = e.target;
        setBillingAddress((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      
      const handleShippingChange = (e) => {
        const { name, value } = e.target;
        setShippingAddress((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
    const handleCreateOrder = (e) => {
  setIsLoading(true);
  e.preventDefault();
  
  // Assuming you want to add billing and shipping addresses before creating the order
  addBilling();
  addShipping();
  
  // Now create the order with the captured dynamic addresses
  createOrder();
};

      const addBilling = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v3/checkouts/${cartId}/billing-address`,
            {
                "first_name": billingAddress.billing_first_name,
                "last_name": billingAddress.billing_last_name,
                "address1": billingAddress.billing_address1,
                "address2": billingAddress.billing_address2,
                "city": billingAddress.billing_city,
                "state": billingAddress.billing_state,
                "country":  billingAddress.billing_country,
                "email":  billingAddress.billing_email,
                "state_or_province": billingAddress.billing_state,
                "postal_code":  billingAddress.billing_postal_code,
                "phone":  billingAddress.billing_phone
              }
          );
          console.log('Billing address added successfuly:', response.data);
        } catch (error) {
          console.error('Error adding Billing address:', error);
        }
      }
      
      const addShipping = async () => {
        try {
            const response = axios.post(
                `${process.env.REACT_APP_API_URL}/api/v3/checkouts/${cartId}/consignments?include=consignments.availableShippingOptions`,
                [
                    {
                      "address": {
                        "email": shippingAddress.shipping_email,
                        "country_code": shippingAddress.shipping_country,
                        "first_name": shippingAddress.shipping_first_name,
                        "last_name": shippingAddress.shipping_last_name,
                        "address1": shippingAddress.shipping_address1,
                        "address2": shippingAddress.shipping_address2,
                        "city": shippingAddress.shipping_city,
                        "state_or_province": shippingAddress.shipping_state,
                        "postal_code": shippingAddress.shipping_postal_code,
                        "phone": shippingAddress.shipping_phone,
                        "custom_fields": [
                          {
                            "field_id": "field_25",
                            "field_value": "Great!"
                          }
                        ]
                    },
                    "line_items": [
                      {
                        "item_id": "00a8e1c3-996f-4786-96ca-2a8a887b6648",
                        "quantity": 1
                      },]
                  }
                  ]
            );
            console.log('Shipping address added successfuly:', response.data);
          } catch (error) {
                console.error('Error adding shipping address:', error);
            }
      }
      const createOrder = async () => {
        
        console.log("billingAddress", billingAddress);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/v2/orders`,
            {
                "status_id": 0,
                "billing_address": {
                  "first_name": billingAddress.billing_first_name,
                  "last_name": billingAddress.billing_last_name,
                 
                  "city": billingAddress.billing_city,
                  "state": billingAddress.billing_state,
                  "zip": billingAddress.billing_postal_code,
                  "country":"United States",
                  "country_iso2": "US",
                  "email": billingAddress.billing_email
                },
                "shipping_addresses": [
                  {
                    "first_name": shippingAddress.shipping_first_name,
                    "last_name": shippingAddress.shipping_last_name,
                    "company": "Acme Pty Ltd",
                    "street_1": "666 Sussex St",
                    "city": shippingAddress.shipping_city,
                    "state": shippingAddress.shipping_state,
                    "zip": shippingAddress.shipping_postal_code,
                    "country": "United States",
                    "country_iso2": "US",
                    "email": billingAddress.billing_email
                  }
                ],
                "products": [
                    {
                      "product_id": "97",
                      "quantity": 2
                    },
                    {
                      "product_id": "98",
                      "quantity": 1
                    }
                  ]
              }
          );
          console.log('Order Created Successfully:', response.data);
          navigate('/order-success');
        } catch (error) {
          console.error('Error Creating Order:', error);
          } finally {
          setIsLoading(false);
          }
      }
    return(
    <>
    <div className="container-fluid bg-secondary mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: "100px"}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3">Checkout</h1>
            <div className="d-inline-flex">
                <p className="m-0"><a href="">Home</a></p>
                <p className="m-0 px-2">-</p>
                <p className="m-0">Checkout</p>
            </div>
        </div>
    </div>

    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            <div className="col-lg-8">
                <div className="mb-4">
                    <h4 className="font-weight-semi-bold mb-4">Billing Address</h4>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>First Name</label>
                            <input className="form-control" type="text" name="billing_first_name" value={billingAddress.billing_first_name}
        onChange={handleBillingChange} placeholder="John" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Last Name</label>
                            <input className="form-control" type="text" name="billing_last_name" value={billingAddress.billing_last_name}
        onChange={handleBillingChange} placeholder="Doe" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>E-mail</label>
                            <input className="form-control" type="text" name="billing_email" value={billingAddress.billing_email}
        onChange={handleBillingChange} placeholder="example@email.com" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Mobile No</label>
                            <input className="form-control" type="text" name="billing_phone" value={billingAddress.billing_phone}
        onChange={handleBillingChange} placeholder="+123 456 789" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Address Line 1</label>
                            <input className="form-control" type="text" name="billing_address1" value={billingAddress.billing_address1}
        onChange={handleBillingChange} placeholder="123 Street" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Address Line 2</label>
                            <input className="form-control" type="text" name="billing_address2" value={billingAddress.billing_address2}
        onChange={handleBillingChange} placeholder="123 Street" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Country</label>
                            <select name="billing_country" value={billingAddress.billing_country}
        onChange={handleBillingChange} className="custom-select">
                                <option code="US" defaultValue>United States</option>
                                <option code="IN" >India</option>
                                <option code="AL" >Albania</option>
                            </select>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>City</label>
                            <input className="form-control" type="text" name="billing_city" value={billingAddress.billing_city}
        onChange={handleBillingChange} placeholder="New York" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>State</label>
                            <input className="form-control" type="text" name="billing_state" value={billingAddress.billing_state}
        onChange={handleBillingChange} placeholder="New York" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>ZIP Code</label>
                            <input className="form-control" type="text" name="billing_postal_code" value={billingAddress.billing_postal_code}
        onChange={handleBillingChange} placeholder="123" />
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" value={billingAddress.shipto}
        onChange={handleBillingChange} id="shipto" name="shipto" />
                                <label className="custom-control-label" htmlFor="shipto"  data-toggle="collapse" data-target="#shipping-address">Ship to different address</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse mb-4" id="shipping-address">
                    <h4 className="font-weight-semi-bold mb-4">Shipping Address</h4>
                    <div className="row">
                    <div className="col-md-6 form-group">
                            <label>First Name</label>
                            <input className="form-control" type="text" name="shipping_first_name" value={shippingAddress.shipping_first_name}
        onChange={handleShippingChange} placeholder="John" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Last Name</label>
                            <input className="form-control" type="text" name="shipping_last_name" value={shippingAddress.shipping_last_name}
        onChange={handleShippingChange} placeholder="Doe" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>E-mail</label>
                            <input className="form-control" type="text" name="shipping_email" value={shippingAddress.shipping_email}
        onChange={handleShippingChange} placeholder="example@email.com" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Mobile No</label>
                            <input className="form-control" type="text" name="shipping_phone" value={shippingAddress.shipping_phone}
        onChange={handleShippingChange} placeholder="+123 456 789" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Address Line 1</label>
                            <input className="form-control" type="text" name="shipping_address1" value={shippingAddress.shipping_address1}
        onChange={handleShippingChange} placeholder="123 Street" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Address Line 2</label>
                            <input className="form-control" type="text" name="shipping_address2" value={shippingAddress.shipping_address2}
        onChange={handleShippingChange} placeholder="123 Street" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Country</label>
                            <select name="shipping_country" value={shippingAddress.shipping_country}
        onChange={handleShippingChange} className="custom-select">
                                <option code="US" defaultValue>United States</option>
                                <option code="IN" >India</option>
                                <option code="AL" >Albania</option>
                            </select>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>City</label>
                            <input className="form-control" type="text" name="shipping_city" value={shippingAddress.shipping_city}
        onChange={handleShippingChange} placeholder="New York" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>State</label>
                            <input className="form-control" type="text" name="shipping_state" value={shippingAddress.shipping_state}
        onChange={handleShippingChange} placeholder="New York" />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>ZIP Code</label>
                            <input className="form-control" type="text" name="shipping_postal_code" value={shippingAddress.shipping_postal_code}
        onChange={handleShippingChange} placeholder="123" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card border-secondary mb-5">
                    <div className="card-header bg-secondary border-0">
                        <h4 className="font-weight-semi-bold m-0">Order Total</h4>
                    </div>
                    <div className="card-body">
                        <h5 className="font-weight-medium mb-3">Products</h5>
                        {cartItems.map(item => (
                        <div key={item.id} className="d-flex justify-content-between">
                            <p>{item.name}</p>
                            <p>${item.sale_price}</p>
                        </div>
                        ))}
                        <hr className="mt-0" />
                        <div className="d-flex justify-content-between mb-3 pt-1">
                            <h6 className="font-weight-medium">Subtotal</h6>
                            <h6 className="font-weight-medium">${cartData.base_amount}</h6>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h6 className="font-weight-medium">Discount</h6>
                            <h6 className="font-weight-medium">${cartData.discount_amount}</h6>
                        </div>
                    </div>
                    <div className="card-footer border-secondary bg-transparent">
                        <div className="d-flex justify-content-between mt-2">
                            <h5 className="font-weight-bold">Total</h5>
                            <h5 className="font-weight-bold">${cartData.cart_amount}</h5>
                        </div>
                    </div>
                </div>
                <div className="card border-secondary mb-5">
                    <div className="card-header bg-secondary border-0">
                        <h4 className="font-weight-semi-bold m-0">Payment</h4>
                    </div>
                    <div className="card-body">
                        <div className="">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="payment" id="banktransfer" />
                                <label className="custom-control-label" htmlFor="banktransfer">Bank Transfer</label>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer border-secondary bg-transparent">
                        <button className="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3" onClick={handleCreateOrder}>Place Order</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
);
};

export default Checkout;