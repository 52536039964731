import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Slider = () => {
    let count = 0;
    const [banners, setBanners] = useState([]);
      
    useEffect(() => {
    const fetchBanners = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/v2/banners`,
            );
        setBanners(response.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    fetchBanners();
    }, []);

    const extractImageElement = (content) => {
        // Create a DOMParser instance
        const parser = new DOMParser();

        // Parse the HTML string
        const htmlDoc = parser.parseFromString(content, 'text/html');

        // Get the image tag
        const imageElement = htmlDoc.querySelector('img');

        return imageElement;

      };
    
  return (
    <>
    {/* <!-- Navbar Start --> */}
    <div id="header-carousel" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
        {Array.isArray(banners)
        ? banners.map(banner => {
            count = count+1;
            const imageElement = extractImageElement(banner.content);
            const imgSrc = imageElement.src;
            const newSrc = imgSrc.replace(/\/%%GLOBAL_CdnStorePath%%\//g, 'https://newstore-z2.mybigcommerce.com/');
            const imageURL = newSrc.replace(/^http:\/\/mydomain\.com:3001/, '');

            return <div className={count > 1 ? 'carousel-item' : 'carousel-item active'} key={banner.id} style={{height: "410px"}}> 
                <img className="img-fluid" src={imageURL} alt={imageElement.alt} />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: "700px"}}>
                        <h4 className="text-light text-uppercase font-weight-medium mb-3">{imageElement.title}</h4>
                        <h3 className="display-4 text-white font-weight-semi-bold mb-4">{imageElement.alt}</h3>
                        <a href="/products-listing" className="btn btn-light py-2 px-3">Shop Now</a>
                    </div>
                </div>
            </div>
          })
        : null}
        
        </div>
        <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
            <div className="btn btn-dark" style={{width: "45px", height: "45px"}}>
                <span className="carousel-control-prev-icon mb-n2"></span>
            </div>
        </a>
        <a className="carousel-control-next" href="#header-carousel" data-slide="next">
            <div className="btn btn-dark" style={{width: "45px", height: "45px"}}>
                <span className="carousel-control-next-icon mb-n2"></span>
            </div>
        </a>
    </div>
    {/* <!-- Navbar End --> */}
    </>
  );
};

export default Slider;