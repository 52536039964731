import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';
Modal.setAppElement('#root'); 

const PopupModal = ({ isOpen, onRequestClose, popupMessage }) => {

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
      const timeout = setTimeout(() => {
        setShowModal(false);
        onRequestClose();
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [isOpen, onRequestClose]);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          width: '400px', // Increase the width to your desired size
          backgroundColor: '#fff',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          borderRadius: '8px',
          padding: '30px', // Increase the padding for more spacing
          textAlign: 'center', // Center the content inside the popup
          position: 'relative',
        },
      }}
    >
      <div>
        <FaCheckCircle style={{ color: '#42b72a', fontSize: '48px', marginBottom: '20px' }} />
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>{popupMessage}</p>
      </div>
    </Modal>
  );
};

PopupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  popupMessage: PropTypes.string.isRequired,
};

export default PopupModal;
