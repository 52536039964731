import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AddToCart from './AddToCart';
import HeaderNavigation from './HeaderNavigation';
import { useLocation } from 'react-router-dom';
import ProductSearch from './ProductSearch';

const ProductList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categoryParam = queryParams.get('category');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState([]);
    const maxVisiblePages = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedSort, setSelectedSort] = useState('');

    const handleSearch = (keyword) => {
      setCurrentPage(1); // Reset page to 1 when performing a new search
      setSearchKeyword(keyword);
    };

    useEffect(() => {
      if (categoryParam) {
        setSelectedCategories([parseInt(categoryParam, 10)]);
      }
    }, [categoryParam]);

    useEffect(() => {
      console.log(selectedCategories);
    }, [selectedCategories]);

    useEffect(() => {
      fetchCategories();
    }, []);

    useEffect(() => {
      // Fetch products based on the search keyword and other filters
      const data = {
        keyword: searchKeyword,
        page: currentPage,
        limit: productsPerPage,
        selected_categories: selectedCategories.join(','),
      };
      fetchProducts(data);
    }, [currentPage, selectedCategories, searchKeyword, selectedSort]);

  const fetchProducts = async (filters) => {
    setLoading(true); 
    let queryParams = `?include=images,options&page=${filters.page}&limit=${filters.limit}`;
    if (filters.keyword) {
      queryParams += `&keyword=${encodeURIComponent(filters.keyword)}`;
    }
    if (filters.selected_categories) {
      queryParams += `&categories:in=${filters.selected_categories}`;
    }
    if (selectedSort === 'popularity') {
      queryParams += '&sort=total_sold&direction=desc';
    } else if (selectedSort === 'price') {
      queryParams += '&sort=price&direction=asc';
    } else if (selectedSort === 'latest') {
      queryParams += '&sort=id&direction=desc';
    } else {
      queryParams += '&sort=id&direction=asc';
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v3/catalog/products${queryParams}`
      );
      setProducts(response.data.data);
      setTotalPages(response.data.meta.pagination.total_pages);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false); // Set loading to false after the API request is complete (success or error)
    }
  };

  const fetchCategories = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v3/catalog/categories?sort=id`
          );
          setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleOnChange = () => {
    const checkboxes = document.querySelectorAll('input[name="category-filter"]:checked');
    const selectedValues = Array.from(checkboxes).map((checkbox) => checkbox.value).join(',');
    const selectedValuesArr = selectedValues.split(',').map((item) => parseInt(item, 10));
    const validSelectedValuesArr = selectedValuesArr.filter((value) => !isNaN(value));
    setSelectedCategories(validSelectedValuesArr);
  };


  return (
    <>
    <HeaderNavigation page='other' />
    
    <div className="container-fluid bg-secondary">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight:"100px"}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3">Our Shop</h1>
            <div className="d-inline-flex">
                <p className="m-0"><a href="">Home</a></p>
                <p className="m-0 px-2">-</p>
                <p className="m-0">Shop</p>
            </div>
        </div>
    </div>
    
    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            <div className="col-lg-3 col-md-12">
                <div className="border-bottom mb-4 pb-4">
                    <h5 className="font-weight-semi-bold mb-4">Filter by Category</h5>
                    <form>
                    {categories.map((category) => (
                        <div key={category.id} className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input
                              name="category-filter"
                              type="checkbox"
                              className="custom-control-input"
                              id={category.id}
                              value={category.id.toString()}
                              onChange={handleOnChange}
                              checked={selectedCategories.includes(category.id)}
                            />
                            <label className="custom-control-label" htmlFor={category.id}>{category.name}</label>
                        </div>
                    ))}
                    </form>
                </div>
            </div>
            
            <div className="col-lg-9 col-md-12">
                <div className="row pb-3">
                <div className="col-12 pb-1">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                        <ProductSearch onSearch={handleSearch} />
                            <div className="dropdown ml-4">
                                <button className="btn border dropdown-toggle" type="button" id="triggerId" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                            Sort by
                                        </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="triggerId">
                                    <a className={`dropdown-item ${selectedSort === 'latest' ? 'active' : ''}`} href="#" onClick={() => setSelectedSort('latest')}>Latest</a>
                                    <a className={`dropdown-item ${selectedSort === 'popularity' ? 'active' : ''}`} href="#" onClick={() => setSelectedSort('popularity')}>Popularity</a>
                                    <a className={`dropdown-item ${selectedSort === 'price' ? 'active' : ''}`} href="#" onClick={() => setSelectedSort('price')}>Price</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {products.map((product) => (
                    <div key={product.id} className="col-lg-4 col-md-6 col-sm-12 pb-1">
                        <div className="card product-item border-0 mb-4">
                            <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                            {product.images.length > 0 ? (
                              <img className="img-fluid w-100" src={product.images[0].url_standard} alt="" key={product.images[0].id} />
                            ) : (
                              <img className="img-fluid w-100" src="/assets/img/default-product.png" alt="Default Image" />
                            )}
                            </div>
                            <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                <h6 className="text-truncate mb-3">{product.name}</h6>
                                <div className="d-flex justify-content-center">
                                    <h6>${product.calculated_price}</h6>
                                    {product.price > product.calculated_price && (
                                      <h6 className="text-muted ml-2"><del>${product.price}</del></h6>
                                    )}
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-between bg-light border">
                                <a href={`/product-detail/${product.id}/${product.sku}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</a>
                                <AddToCart product={product}/>
                            </div>
                        </div>
                    </div>
                    ))}
                    {products.length === 0 && (
                      <div className="no-products-message" style={{textAlign:"center", padding:"20px", fontWeight: "bold"}}>
                        No products found.
                      </div>
                    )}
                </div>
            </div>
        </div>
    </div>
    {totalPages > 1 ? (
        <div className="container-fluid pt-3 pb-5">
        <nav aria-label="Product pagination">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 1)}
                disabled={currentPage === 1 || loading}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages })
              .slice(startPage - 1, endPage) // Display only the pages within the range
              .map((_, index) => (
                <li
                  key={startPage + index}
                  className={`page-item ${currentPage === startPage + index ? 'active' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(startPage + index)}
                    disabled={loading}
                  >
                    {startPage + index}
                  </button>
                </li>
              ))}
            <li
              className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={currentPage === totalPages || loading}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
        {/* Display the current active page and the total number of pages */}
        <p className="text-center">Page {currentPage} of {totalPages}</p>
      </div>
      ) : null}
     
    </>
  );
};

export default ProductList;