
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PopupModal from './PopupModal';
import { fetchCartItems } from './fetchCartItems';
import { setCartItemsCount } from './cartReducer'; // Import the action
import { useDispatch } from 'react-redux';

const AddToCart = (data) => {
  
  const dispatch = useDispatch();
  const productId = data.product.id // ID of the product to add
  const currentPage = data.product.page;
  const product = data.product;
  const productVariant = data.productVariant;
  const [cartId, setCartId] = useState(null); // ID of the cart
  const [isLoading, setIsLoading] = useState(false);
  const [storedCartId, setStoredCartId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const cartId = localStorage.getItem('cartId');
    if(cartId !== null) setStoredCartId(cartId);
}, [storedCartId]);

useEffect(() => {
  // Update the cart items count in the App component whenever cartItemsCount changes
  if (cartId !== null) {
    fetchCartItems(cartId)
      .then((data) => {
        setCartItemsCount(data.line_items.physical_items.length);
      })
      .catch((error) => {
        console.error('Error fetching cart items:', error);
      });
  }
}, [cartId]);
  
  
  // Add to cart function
  const addToCart = async () => {
    setIsLoading(true);
       // Fetch product options and variants only when needed
       let options = null;
       let productVariantarray = [];
       if (product.options || productVariant) {
         try {
           const optionsResponse = await axios.get(
             `${process.env.REACT_APP_API_URL}/api/v3/catalog/products/${productId}/options`
           );
           options = optionsResponse?.data?.data[0]?.option_values;
   
           const variantsResponse = await axios.get(
             `${process.env.REACT_APP_API_URL}/api/v3/catalog/products/${productId}/variants`
           );
           productVariantarray = variantsResponse.data.data;
         } catch (error) {
           console.error('Error fetching products:', error);
         }
      }  
      
    try {
      // Add to cart logic...
      let variantIdToAdd = null;
      if (productVariant) {
        const defaultValue = productVariant.find((item) => item.is_default === true);
        if (defaultValue) {
          for (const element of productVariantarray) {
            const optionValue = element.option_values.find((opt) => opt.id === defaultValue.id);
            if (optionValue) {
              variantIdToAdd = element.id;
            }
          }
        }
      } else if (options) {
        const defaultValue = options.find((item) => item.is_default === true);
        if (defaultValue) {
          for (const element of productVariantarray) {
            const optionValue = element.option_values.find((opt) => opt.id === defaultValue.id);
            if (optionValue) {
              variantIdToAdd = element.id;
            }
          }
        }
      }
      const cartsId = localStorage.getItem('cartId');
      let apiUrl = `${process.env.REACT_APP_API_URL}/api/v3/carts/${cartsId}/items`;
      if (cartsId === null) {
        apiUrl = `${process.env.REACT_APP_API_URL}/api/v3/carts`;
      }

      const response = await axios.post(apiUrl, {
        channel_id: 1,
        customer_id: 1,
        line_items: [
          {
            quantity: 1,
            product_id: productId,
            variant_id: variantIdToAdd,
          },
        ],
      });

      console.log('Item added to cart:', response.data);
      const cartId = response.data.data.id;
      dispatch(setCartItemsCount(response.data.data.line_items.physical_items.length));
      localStorage.setItem('cartId', cartId);
      localStorage.setItem('cartCount', response.data.data.line_items.physical_items.length);
      setIsModalOpen(true); // Show the modal when the item is added to the cart
    } catch (error) {
      console.error('Error adding item to cart:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  } 
  
return (
    <>
      {currentPage === 'productDetail' ? (
        <button onClick={addToCart}className="btn btn-primary px-3"><i className="fa fa-shopping-cart mr-1"></i> Add To Cart</button>
      ) : (
        <a onClick={addToCart} className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</a>
      )}
      <PopupModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        popupMessage="Item added to cart!"
      />
    </>
);
};
export default AddToCart;
