import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ProductCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v3/catalog/categories?sort=id&limit=6`
          );
          setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  return (
        <div className="container-fluid pt-5">
            <div className="row px-xl-5 pb-3">
                {categories.map((category) => (
                    <div className="col-lg-4 col-md-6 pb-1" key={category.id}>
                        <div className="cat-item d-flex flex-column border mb-4" style={{padding: "30px"}}>
                            <p className="text-right">{category.name}</p>
                            <Link
                                to={`/products-listing?category=${category.id}`} // Pass the selected category as a query parameter
                                key={category.id}
                                className="cat-img position-relative overflow-hidden mb-3"
                              >
                                <img className="img-fluid" src={category.image_url} alt="" />
                              </Link>
                            <h5 className="font-weight-semi-bold m-0">{category.name}</h5>
                        </div>
                    </div>
                ))}
            </div>
        </div>
  );
};

export default ProductCategories;