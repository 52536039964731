import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderNavigation from './HeaderNavigation';

const TermsAndConditions = () =>  {
    const [content, setContent] = useState(null);
  
    useEffect(() => {
        const apiUrl = `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}&content_type=termsAndConditions&include=2`;

        axios.get(apiUrl)
        .then((response) => {
            
          if (response.data.items.length > 0) {
            setContent(response.data);
            console.log("HI");
            console.log('content :' , content);
          } else {
            console.error('No content found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching content:', error);
        });
    }, []);
  
    console.log('Content:', content); // Log the content to inspect it in the console
  
    return (
      <>

      <HeaderNavigation page='other' />

      <div>
        {content && (
          <div>
  
            {/* Display the details */}
            <div >
              <div dangerouslySetInnerHTML={{ __html: content.items[0]?.fields?.textt?.content?.[0]?.content[0]?.value }} />
            </div>
          </div>
        )}
      </div>

      </>
    );
  };
  
  export default TermsAndConditions;
  