import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderNavigation from './HeaderNavigation';

const ContactUs = () => {
const [footerData, setFooterData] = useState(null);
useEffect(() => {
  axios.get('https://cdn.contentful.com/spaces/bi4um5vu22vt/entries?access_token=xCQb9umhXB6_IYZoYhydt33enHVhL-VqwoC5dYk8zdY&content_type=footerMenu', {
   
  })
  .then((response) => {
    // Assuming only one entry for the footer content type
    if (response.data.items.length > 0) {
      setFooterData(response.data.items[0].fields);
    }
  })
  .catch((error) => {
    console.error('Error fetching data from Contentful:', error);
  });
}, []);

if (!footerData) {
  // Render a loading state or return null until the data is fetched
  return null;
}
const {  address, email, phoneNumber } = footerData;

  return (

    <>
    <HeaderNavigation />
    <div className="container mt-5">
      <div className="row">
        {/* Form */}
        <div className="col-md-6">
          <h2>Contact Us</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" id="name" required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input type="email" className="form-control" id="email" required />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" id="message" rows="5" required></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>

        {/* Organization's Information */}
        <div className="col-md-6">
          <h2>Contact Information : </h2>
          <div style={{ width: '500px', height: '250px', position: 'relative' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13718.775971294963!2d76.841046!3d30.727002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f92df0c883d17%3A0xe1d63b12e1f92a35!2sNet%20Solutions!5e0!3m2!1sen!2sin!4v1690462463189!5m2!1sen!2sin"
              title="Organization's Location"
              width="100%"
              height="100%"
              style={{ border: 'none', position: 'absolute', top: 0, left: 0 , paddingBottom: 15}}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
           <p>
            {/* Add your organization's information here */}
            Address: {address}<br />
            Phone: {phoneNumber}<br />
            Email: {email}<br />
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactUs;