import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import CartListing from './Components/CartListing';
import ProductList from './Components/ProductList';
import ProductDetail from './Components/ProductDetail';
import Login from './Components/Login';
import Register from './Components/Register';
import Checkout from './Components/Checkout';
import ContactUs from './Components/ContactUs';
import TermsAndConditions from './Components/TermsAndConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import OrderSuccess from './Components/OrderSuccess';
import { fetchCartItems } from './Components/fetchCartItems';
import { useSelector,useDispatch } from 'react-redux';
import PopupModal from './Components/PopupModal';
import Footer from './Components/Footer';
import { setCartItemsCount } from './Components/cartReducer';


function App() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const cartItemsCount = useSelector((state) => state.cart.cartItemsCount);
    
  const handleSubmit = (e) => {
    let  apiUrl = `${process.env.REACT_APP_API_URL}/api/v3/customers/subscribers`;
    e.preventDefault();
    const formData = {
      name: name,
      email: email,
    };
    axios.post(apiUrl, formData)
      .then((response) => {
        setIsModalOpen(true);
            setTimeout(() => {
                setIsModalOpen(false);
              }, 2000);
        setName('');
        setEmail('');
      })
      .catch((error) => {
        console.error('API call error:', error);
      });
  };

    

    useEffect(() => {
        let cartId = localStorage.getItem('cartId');
        if(cartId !== null) {
            fetchCartItems(cartId)
            .then((data) => {
                dispatch(setCartItemsCount(data.line_items.physical_items.length));
                localStorage.setItem('cartData', JSON.stringify(data));
                localStorage.setItem('cartItems', JSON.stringify(data.line_items.physical_items));
                localStorage.setItem('cartCount', data.line_items.physical_items.length);
            })
            .catch((error) => {
                console.error('Error fetching cart items:', error);
            });
        }
      }, [dispatch]);
    

  return (
    <Router>
    <>
    {/* Topbar Start */}
    <div className="container-fluid">
        <div className="row bg-secondary py-2 px-xl-5">
            <div className="col-lg-6 d-none d-lg-block">
                <div className="d-inline-flex align-items-center">
                    <a className="text-dark" href="">FAQs</a>
                    <span className="text-muted px-2">|</span>
                    <a className="text-dark" href="">Help</a>
                    <span className="text-muted px-2">|</span>
                    <a className="text-dark" href="">Support</a>
                </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
                <div className="d-inline-flex align-items-center">
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a className="text-dark pl-2" href="">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
        <div className="row align-items-center py-3 px-xl-5">
            <div className="col-lg-3 d-none d-lg-block">
                <NavLink exact="true" to="/" className="text-decoration-none">
                    <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shopper</h1>
                </NavLink>
            </div>
            <div className="col-lg-6 col-6 text-left">
                {/* <ProductSearch /> */}
            </div>
            <div className="col-lg-3 col-6 text-right">
                <a href="" className="btn border">
                    <i className="fas fa-heart text-primary"></i>
                    <span className="badge">0</span>
                </a>
                <NavLink to="/cart-listing" className="btn border">
                    <i className="fas fa-shopping-cart text-primary"></i>
                    <span className="badge">{cartItemsCount}</span>
                </NavLink>
            </div>
        </div>
    </div>
    {/* <!-- Topbar End --> */}

    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products-listing" element={<ProductList />} />
        <Route path="cart-listing" element={<CartListing />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="order-success" element={<OrderSuccess />} />
        <Route path="product-detail/:id/:sku" element={<ProductDetail />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Routes>

 
    {/* <!-- Footer Start --> */}
    <Footer/>
    {/* <!-- Footer End --> */}
    <PopupModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        popupMessage="Subscribed successfully!!"
      />  
    </>
    </Router>
  );
}

export default App;
