import axios from 'axios';

export const fetchCartItems = async (storedCartId) => {
  try {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v3/carts/${storedCartId}`
        );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching cart items:', error);
    throw error;
  }
};
