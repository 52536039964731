import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import AddToCart from './AddToCart';
import HeaderNavigation from './HeaderNavigation';

import { useParams } from 'react-router-dom';

const ProductDetail = (props) => {
    const { id, sku } = useParams();
    const [product, setProduct] = useState(null);
    const [productData, setProductData] = useState(null);
    const [productImages, setProductImages] = useState([]);
    const [productColors, setProductVariants] = useState([]);
    const [selectedColor, setSelectedColor] = useState(null);
    const [productReviews, setProductReviews] = useState([]);
    const countReviews = productReviews.length;
    const [quantity, setQuantity] = useState(1);

    
  // Add a ref to the carousel container
  const carouselRef = useRef(null);

  // Add state to track the active carousel item
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/contentful/entries?content_type=products&fields.sku=${sku}`;

    axios.get(apiUrl)
      .then(response => {
        const data = response.data;
        if (data.items && data.items.length > 0) {
          setProductData(data.items[0].fields);
          console.log(data.items[0].fields);
        }
      })
      .catch(error => {
        console.error('Error fetching product data from Contentful:', error);
      });
  }, []);

  // Function to handle carousel navigation
  const handleCarouselNext = () => {
    setActiveCarouselIndex((prevIndex) =>
      prevIndex === productImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleCarouselPrev = () => {
    setActiveCarouselIndex((prevIndex) =>
      prevIndex === 0 ? productImages.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v3/catalog/products/${id}`,
        );
        setProduct(response.data.data);
        fetchProductImages(response.data.data);
        fetchProductReviews(response.data.data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, []);
  
  const handleColorChange = (colorId,color) => {
    productColors.map((item) => (
        item.is_default = false
    ))
    color.is_default = true;
    setSelectedColor(colorId);
  };

  useEffect(() => {
    const fetchProductvairantoption = async () => {
      try {
        const response = await axios.get(
            `http://localhost:3001/api/v3/catalog/products/${id}/options`,
        );
        setProductVariants(response.data?.data[0]?.option_values);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };
    fetchProductvairantoption();
  }, []);

  useEffect(() => {
    // If no color is selected, set the default color if available
    if(productColors){
    if (!selectedColor) {
      const defaultColor = productColors.find((color) => color.is_default === true);
      if (defaultColor) {
        setSelectedColor(defaultColor.id); 
      }
    }
   }
  }, [selectedColor, productColors]);


  const fetchProductImages = async (productData) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v3/catalog/products/${productData.id}/images`,
      );
      setProductImages(response.data.data);
      setActiveCarouselIndex(0);
    } catch (error) {
      console.error('Error fetching product images:', error);
    }
  };

  const fetchProductReviews = async (productData) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v3/catalog/products/${productData.id}/reviews`,
      );
      setProductReviews(response.data.data);

    } catch (error) {
      console.error('Error fetching product images:', error);
    }
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };
  
  if (!product) {
    return <div>Loading...</div>;
  }
  product.page = 'productDetail';
  return (
    <>
    <HeaderNavigation page='other' />
    
    <div className="container-fluid py-5">
        <div className="row px-xl-5">
            <div className="col-lg-5 pb-5">
                <div className="carousel">
                    <div className="carousel-inner border">
                    {productImages.length === 0 ? (
                        // Render default image when productImages is empty
                        <div className="carousel-item active">
                            <img className="w-100 h-100" src="/assets/img/default-product.png" alt="Default Image" />
                        </div>
                        ) : (
                        // Render individual images from productImages array
                        productImages.map((image, index) => (
                          <div
                          key={image.id}
                          className={`carousel-item ${ index === activeCarouselIndex ? 'active' : ''
                          }`} >
                          <img  className="w-100 h-100" src={image.url_standard} alt={image.description} />
                        </div>
                        ))
                        )}
                    </div>
                    <a className="carousel-control-prev" onClick={handleCarouselPrev}>
                     <i className="fa fa-2x fa-angle-left text-dark"></i>
              </a>
              <a className="carousel-control-next" onClick={handleCarouselNext} >
                <i className="fa fa-2x fa-angle-right text-dark"></i>
              </a>
                </div>
            </div>

            <div className="col-lg-7 pb-5">
                <h3 className="font-weight-semi-bold">{product.name}</h3>
                <div className="d-flex mb-3">
                {countReviews > 0 && (
                    <div className="text-primary mr-2">
                        <small className="fas fa-star"></small>
                        <small className="fas fa-star"></small>
                        <small className="fas fa-star"></small>
                        <small className="fas fa-star"></small>
                        <small className="far fa-star"></small>
                    </div>
                )}
                </div>
                <h3 className="font-weight-semi-bold mb-4">${product.price}</h3>
                <p className="mb-4" dangerouslySetInnerHTML={{__html: product.description}}></p>
                {Array.isArray(productColors) ?
                <div className="d-flex mb-4">
                    <p className="text-dark font-weight-medium mb-0 mr-3">Colors:</p>
                    <form>
                    {productColors.map((color) => (
                         <div key={color.id} className="custom-control custom-radio custom-control-inline">
                         <input
                           type="radio"
                           className="custom-control-input"
                           id={`color-${color.id}`}
                           name="color"
                           value={color.id}
                           checked={selectedColor === color.id}
                           onChange={() => handleColorChange(color.id,color)}
                         />
                         <label className="custom-control-label" htmlFor={`color-${color.id}`}>
                           {color.label}
                         </label>
                       </div>
                        ))}
                    </form>       
                </div>
              : null}
                
                <div className="d-flex align-items-center mb-4 pt-2">
                    <div className="input-group quantity mr-3" style={{width: "130px"}}>
                        <div className="input-group-btn">
                            <button className="btn btn-primary btn-minus" onClick={handleDecrement}>
                                <i className="fa fa-minus"></i>
                            </button>
                        </div>
                        <input type="text" value={quantity} readOnly className="form-control bg-secondary text-center" />
                        <div className="input-group-btn">
                            <button className="btn btn-primary btn-plus" onClick={handleIncrement}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <AddToCart product={product} productVariant={productColors}/>
                </div>
                <div className="d-flex pt-2">
                    <p className="text-dark font-weight-medium mb-0 mr-2">Share on:</p>
                    <div className="d-inline-flex">
                        <a className="text-dark px-2" href="">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="text-dark px-2" href="">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a className="text-dark px-2" href="">
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                        <a className="text-dark px-2" href="">
                            <i className="fab fa-pinterest"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="row px-xl-5">
            <div className="col">
                <div className="nav nav-tabs justify-content-center border-secondary mb-4">
                    <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1">Description</a>
                    <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-2">Information</a>
                    <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-3">Reviews {countReviews}</a>
                </div>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab-pane-1">
                        <h4 className="mb-3">Product Description</h4>
                        <div dangerouslySetInnerHTML={{__html: product.description}}></div>
                    </div>
                    <div className="tab-pane fade" id="tab-pane-2">
                        <h4 className="mb-3">Additional Information</h4>
                        <p dangerouslySetInnerHTML={{__html: productData?.additionalInformation}}></p>
                    </div>
                    <div className="tab-pane fade" id="tab-pane-3">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="mb-4">{countReviews > 0 ? countReviews+' Review(s) '+product.name : 'No Reviews Yet'}</h4>
                                {productReviews.map((productReview) => (
                                    <div key={productReview.id} className="media mb-4">
                                        <div className="media-body">
                                            <h6>{productReview.name}<small> - <i>{productReview.date_created.toLocaleString()}</i></small></h6>
                                            <div className="text-primary mb-2">
                                                {Array.from({ length: 5 }, (_, index) => (
                                                <i key={index} className={productReview.rating > index ? 'fas fa-star' : 'far fa-star'}></i>
                                                ))}
                                            </div>
                                            <h5>{productReview.title}</h5>
                                            <p>{productReview.text}</p>
                                        </div>
                                    </div>
                                    ))}
                            </div>
                            <div className="col-md-6">
                                <h4 className="mb-4">Leave a review</h4>
                                <small>Your email address will not be published. Required fields are marked *</small>
                                <div className="d-flex my-3">
                                    <p className="mb-0 mr-2">Your Rating * :</p>
                                    <div className="text-primary">
                                        <i className="far fa-star"></i>
                                        <i className="far fa-star"></i>
                                        <i className="far fa-star"></i>
                                        <i className="far fa-star"></i>
                                        <i className="far fa-star"></i>
                                    </div>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="message">Your Review *</label>
                                        <textarea id="message" cols="30" rows="5" className="form-control"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Your Name *</label>
                                        <input type="text" className="form-control" id="name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Your Email *</label>
                                        <input type="email" className="form-control" id="email" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <input type="submit" value="Leave Your Review" className="btn btn-primary px-3" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default ProductDetail;