import React, { useEffect, useState } from 'react';
import axios from 'axios';

const HeaderCategories = () => {
    const mystyle = {
        height: "65px",
        marginTop: "-1px",
        padding: "0 30px",
      };

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
      }, []);
    
      const fetchCategories = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/v3/catalog/categories/tree`
              );
              setCategories(response.data.data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };
    
  return (
    <>
    <a className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100 collapsed" data-toggle="collapse" href="#navbar-vertical" style={mystyle}>
        <h6 className="m-0">Categories</h6>
        <i className="fa fa-angle-down text-dark"></i>
    </a>
    <nav className="collapse show navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 border-bottom-0" id="navbar-vertical">
        <div className="navbar-nav w-100 overflow-hidden" style={{height: "410px"}}>
        {categories.map((category) => (
                <div key={category.id} className="nav-item dropdown">
                    <a href={`/products-listing?category=${category.id}`} className="nav-link" data-toggle={category.children.length > 0 ? 'dropdown' : ''}>{category.name} <i className={category.children.length > 0 ? 'fa fa-angle-down float-right mt-1' : ''}></i></a>
                    {category.children.length > 0 && 
                        <div className="dropdown-menu position-absolute bg-secondary border-0 rounded-0 w-100 m-0">
                                {category.children.map((child) => (
                                <a href={`/products-listing?category=${child.id}`} key={child.id} className="dropdown-item">{child.name}</a>
                                ))}
                        </div>
                    }
                </div>
            ))}
        </div>
    </nav>
    </>
  );
};

export default HeaderCategories;