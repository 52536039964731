import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FeaturedProducts from './FeaturedProducts';
import PopupModal from './PopupModal';
import LatestProducts from './LatestProducts';
import ProductCategories from './ProductCategories';
// import Promotions from './Promotions';
import HeaderNavigation from './HeaderNavigation';
const Home = () => {

    const [email, setEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmit = (e) => {
        let  apiUrl = `${process.env.REACT_APP_API_URL}/api/v3/customers/subscribers`;
        e.preventDefault();
        const formData = {
          email: email,
        };
        axios.post(apiUrl, formData)
          .then((response) => {
            setIsModalOpen(true);
            setEmail('');
          })
          .catch((error) => {
            console.error('API call error:', error);
          });
      };

  return (
    <>
    <HeaderNavigation page='home' />
    
    {/* <!-- Featured Start --> */}
    <div className="container-fluid pt-5">
        <div className="row px-xl-5 pb-3">
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div className="d-flex align-items-center border mb-4" style={{padding: "30px"}}>
                    <h1 className="fa fa-check text-primary m-0 mr-3"></h1>
                    <h5 className="font-weight-semi-bold m-0">Quality Product</h5>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div className="d-flex align-items-center border mb-4" style={{padding: "30px"}}>
                    <h1 className="fa fa-shipping-fast text-primary m-0 mr-2"></h1>
                    <h5 className="font-weight-semi-bold m-0">Free Shipping</h5>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div className="d-flex align-items-center border mb-4" style={{padding: "30px"}}>
                    <h1 className="fas fa-exchange-alt text-primary m-0 mr-3"></h1>
                    <h5 className="font-weight-semi-bold m-0">14-Day Return</h5>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div className="d-flex align-items-center border mb-4" style={{padding: "30px"}}>
                    <h1 className="fa fa-phone-volume text-primary m-0 mr-3"></h1>
                    <h5 className="font-weight-semi-bold m-0">24/7 Support</h5>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Featured End --> */}

    <ProductCategories />

    {/* <!-- Offer Start --> */}
    
    {/* <!--  <Promotions /> --> */}

    {/* <!-- Offer End --> */}

    <FeaturedProducts />

    {/* <!-- Subscribe Start --> */}
    <div className="container-fluid bg-secondary my-5">
        <div className="row justify-content-md-center py-5 px-xl-5">
            <div className="col-md-6 col-12 py-5">
                <div className="text-center mb-2 pb-2">
                    <h2 className="section-title px-5 mb-3"><span className="bg-secondary px-2">Stay Updated</span></h2>
                    <p>Amet lorem at rebum amet dolores. Elitr lorem dolor sed amet diam labore at justo ipsum eirmod duo labore labore.</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input type="text" className="form-control border-white p-4" placeholder="Email Goes Here" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <div className="input-group-append">
                            <button className="btn btn-primary px-4" type="submit">Subscribe</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    {/* <!-- Subscribe End --> */}
    
    <LatestProducts />
    <PopupModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        popupMessage="Subscribed successfully!!"
      />

    </>
  );
};

export default Home;