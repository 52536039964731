import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import PopupModal from './PopupModal';

const Footer = () => {
  const [name, setName] = useState('');
  const [email1, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [footerData, setFooterData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const apiUrl = `${process.env.REACT_APP_API_URL}/crm/v3/objects/contacts`;
  
    // Construct the data object according to HubSpot's API requirements
    const data = {
      properties: {
        "email":email1,
        "firstname":name
      }
    };

  
    // Make the POST request with the properly formatted data and headers
    axios.post(apiUrl, data)
      .then((response) => {
        setIsModalOpen(true);
        setTimeout(() => {
          setIsModalOpen(false);
        }, 2000);
        setName('');
        setEmail('');
      })
      .catch((error) => {
        console.error('API call error:', error);
      });
  };
//  useEffect(() => {
//     axios.get(`${process.env.REACT_APP_API_URL}/contacts/v1/lists/all/contacts/all`)
//       .then(response => {
//         const retrievedContacts = response.data.contacts;
//         setContacts(retrievedContacts);
//       })
//       .catch(error => {
//         console.error('Error fetching contacts:', error);
//       });
//   }, []);


  useEffect(() => {
    axios.get('https://cdn.contentful.com/spaces/bi4um5vu22vt/entries?access_token=xCQb9umhXB6_IYZoYhydt33enHVhL-VqwoC5dYk8zdY&content_type=footerMenu', {
     
    })
    .then((response) => {
      // Assuming only one entry for the footer content type
      if (response.data.items.length > 0) {
        setFooterData(response.data.items[0].fields);
      }
    })
    .catch((error) => {
      console.error('Error fetching data from Contentful:', error);
      setFooterData({  // Set default values for fallback content
        siteName: 'Your Site Name',
        footerText: 'Default footer text',
        address: 'Default address',
        email: 'info@example.com',
        phoneNumber: '+012 345 67890',
        newsletterTitle: 'Newsletter',
        newsletterButtonText: 'Subscribe Now',
        logoImage: './assets/img/default-logo.png',
        paymentsImage: './assets/img/default-payments.png',
      });
    });
  }, []);

  if (!footerData) {
    // Render a loading state or return null until the data is fetched
    return null;
  }

  const { title, footerText, address, email, phoneNumber,newsletterTitle, newsletterButtonText,payments } = footerData;

  return (
    <>
    <div className="container-fluid bg-secondary text-dark mt-5 pt-5">
      <div className="row px-xl-5 pt-5">
        <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
          <NavLink exact="true" to="/" className="text-decoration-none">
            <h1 className="mb-4 display-5 font-weight-semi-bold">
              <span className="text-primary font-weight-bold border border-white px-3 mr-1">E</span>
              {title}
            </h1>
          </NavLink>
          <p>{footerText}</p>
          <p className="mb-2">
            <i className="fa fa-map-marker-alt text-primary mr-3"></i>
            {address}
          </p>
        
          <p className="mb-2">
            <i className="fa fa-envelope text-primary mr-3"></i>
            {email}
          </p>
          <p className="mb-0">
            <i className="fa fa-phone-alt text-primary mr-3"></i>
            {phoneNumber}
          </p>
        </div>
        <div className="col-lg-8 col-md-12">
          <div className="row">
            <div className="col-md-4 mb-5">
              <h5 className="font-weight-bold text-dark mb-4">Quick Links</h5>
              <div className="d-flex flex-column justify-content-start">
                  <NavLink exact="true" to="/" className="text-dark mb-2" > <i className="fa fa-angle-right mr-2"></i>Home</NavLink>
                  <NavLink to="/products-listing" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"> </i>Products</NavLink>
                  <NavLink to="/cart-listing"  className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Cart</NavLink>
                  <NavLink to="/checkout" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Checkout</NavLink>
                  <NavLink to="/contact-us" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Contact Us</NavLink>
                  <NavLink to="/terms-and-conditions" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Terms And Conditions</NavLink>
                  <NavLink to="/privacy-policy" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Privacy Policy</NavLink>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <h5 className="font-weight-bold text-dark mb-4">Quick Links</h5>
              <div className="d-flex flex-column justify-content-start">
                  <NavLink exact="true" to="/" className="text-dark mb-2" > <i className="fa fa-angle-right mr-2"></i>Home</NavLink>
                  <NavLink to="/products-listing" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"> </i>Products</NavLink>
                  <NavLink to="/cart-listing"  className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Cart</NavLink>
                  <NavLink to="/checkout" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Checkout</NavLink>
                  <NavLink to="/contact-us" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Contact Us</NavLink>
                  <NavLink to="/terms-and-conditions" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Terms And Conditions</NavLink>
                  <NavLink to="/privacy-policy" className="text-dark mb-2"> <i className="fa fa-angle-right mr-2"></i>Privacy Policy</NavLink>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <h5 className="font-weight-bold text-dark mb-4">{newsletterTitle}</h5>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input type="text" className="form-control border-0 py-4" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="form-group">
                  <input type="email" className="form-control border-0 py-4" placeholder="Your Email" value={email1} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div>
                  <button className="btn btn-primary btn-block border-0 py-3" type="submit">{newsletterButtonText}</button>
                </div>
              </form>
            </div>
            {/* Other columns */}
          </div>
        </div>
      </div>
      <div className="row border-top border-light mx-xl-5 py-4">
        <div className="col-md-6 px-xl-0">
          <p className="mb-md-0 text-center text-md-left text-dark">
            &copy; <a className="text-dark font-weight-semi-bold" href="#">{title}</a>. All Rights Reserved. Designed
            by <a className="text-dark font-weight-semi-bold" href="https://htmlcodex.com">HTML Codex</a><br />
            Distributed By <a href="https://themewagon.com" target="_blank">ThemeWagon</a>
          </p>
        </div>
        <div className="col-md-6 px-xl-0 text-center text-md-right">
          <img className="img-fluid" src={payments} alt="" />
        </div>
      </div>
    </div>
    <PopupModal
    isOpen={isModalOpen}
    onRequestClose={() => setIsModalOpen(false)}
    popupMessage="Subscribed successfully!!"
  />  
</>
  );
};

export default Footer;
