import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderCategories from './HeaderCategories';
import Slider from './Slider';

const HeaderNavigation = (data) => {
    const page = data.page;
  return (
    <>
    {/* <!-- Navbar Start --> */}
    <div className="container-fluid mb-5">
        <div className="row border-top px-xl-5">
            <div className="col-lg-3 d-none d-lg-block">
            {page === 'home' && (
                <HeaderCategories />
                )}
            </div>
            <div className="col-lg-9">
                <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                    <a href="" className="text-decoration-none d-block d-lg-none">
                        <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shopper</h1>
                    </a>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav mr-auto py-0">
                            <NavLink exact="true" to="/" className="nav-item nav-link" activeclassname="active">Home</NavLink>
                            <NavLink to="/products-listing" className="nav-item nav-link" activeclassname="active">Products</NavLink>
                            <NavLink to="/cart-listing" className="nav-item nav-link" activeclassname="active">Cart</NavLink>
                            <NavLink to="/checkout" className="nav-item nav-link" activeclassname="active">Checkout</NavLink>
                            <NavLink to="/contact-us" className="nav-item nav-link" activeclassname="active">Contact Us</NavLink>
                        </div>
                        <div className="navbar-nav ml-auto py-0">
                            <a href="/login" className="nav-item nav-link">Login</a>
                            <a href="/register" className="nav-item nav-link">Register</a>
                        </div>
                    </div>
                </nav>
                {page === 'home' && (
                <Slider />
                )}
            </div>
        </div>
    </div>
    {/* <!-- Navbar End --> */}
    </>
  );
};

export default HeaderNavigation;