import React from 'react';
import HeaderNavigation from './HeaderNavigation';

const OrderSuccess = () => {
  const iconStyle = {
    color: '#9ABC66',
    fontSize: '100px',
    lineHeight: '200px',
    marginLeft:'-15px'
  };
  const cardStyle = {
    background: 'white',
    padding: '60px',
    borderRadius: '4px',
    display: 'inline-block',
    margin: '0 auto'
  };

  return (
    <>
        <div style={cardStyle} className="card container-fluid  mb-5 text-center">
        <div style={{borderRadius: "200px", height:"200px", width:"200px", background:"#F8FAF5", margin:"0 auto"}}>
            <i style={iconStyle} className="checkmark">✓</i>
        </div>
            <h1>Success</h1> 
            <p>We received your purchase request;<br/> we'll be in touch shortly!</p>
        </div>
    </>
  );
};

export default OrderSuccess;