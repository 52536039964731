import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeaderNavigation from './HeaderNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems } from './cartReducer';

const CartListing = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.cartItems);
    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [cartData, setCartData] = useState([]);
    const [storedCartId, setStoredCartId] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponError, setCouponError] = useState('');

    useEffect(() => {
        const cartId = localStorage.getItem('cartId');
        if(cartId !== null) {
            setStoredCartId(cartId);
            fetchCartItems(cartId);
        }
    }, [cartItemsCount]);

    useEffect(() => {
    // When cartItems changes in Redux, update the local cartItems state
    setCartItems(cartItems);
    }, [cartItems]);

    const fetchCartItems = async (storedCartId) => {
        try {
            const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v3/carts/${storedCartId}?include=lineItems.physicalItems.options`
            );
                setStoredCartId(response.data.data.id);
                setCartData(response.data.data);
                setCartItems(response.data.data.line_items.physical_items);
                dispatch(setCartItems(response.data.data.line_items.physical_items)); // Dispatch the updated cart items to Redux
                setCartItemsCount(response.data.data.line_items.physical_items.length);
                if(response.data.data.coupons.length > 0){
                    setCouponApplied(true);
                }
            } catch (error) {
                localStorage.removeItem('cartId');
                setCartItemsCount(0);
                console.error('Error fetching cart items:', error);
            }
        };

  const handleDelete = async (cartItemId) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v3/carts/${storedCartId}/items/${cartItemId}`)
        console.log('Item deleted successfully', response);
        if(response.data.length == 0){
            setCartItemsCount(0);
            localStorage.setItem('cartCount', 0);
            window.location.reload(false);
        }else{
            setCartItemsCount(response.data.data.line_items.physical_items.length);
            localStorage.setItem('cartCount', response.data.data.line_items.physical_items.length);
            fetchCartItems(storedCartId); // Fetch the updated cart items after deletion
            dispatch(setCartItems(response.data.data.line_items.physical_items)); // Dispatch the updated cart items to Redux
        }
      
    } catch (error) {
        console.error('Error deleting item:', error);
    }
  };

  // Function to apply the coupon
  const applyCoupon = async (event) => {
    event.preventDefault();
    if (!couponCode || couponCode.trim() === '') {
        setCouponError('Coupon code cannot be empty');
        return; 
      }
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v3/checkouts/${storedCartId}/coupons`,
        { coupon_code: couponCode }
      );
      setCouponError('');
      fetchCartItems(storedCartId);
    } catch (error) {
        console.error('Error applying coupon:', error);
        setCouponError(error.response.data.title);
    }
  };

  // Function to clear the applied coupon
  const clearCoupon = async (couponCode) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v3/checkouts/${storedCartId}/coupons/${couponCode}`)
        console.log('Coupon deleted successfully', response);
        setCouponApplied('');
    } catch (error) {
        console.error('Error deleting coupon:', error);
    }
  };

  if(storedCartId == null){
    return (
        <>
    <HeaderNavigation page='other' />
    
    <div className="container-fluid bg-secondary mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: "100px"}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3">Shopping Cart</h1>
            <div className="d-inline-flex">
                <p className="m-0"><a href="">Home</a></p>
                <p className="m-0 px-2">-</p>
                <p className="m-0">Shopping Cart</p>
            </div>
        </div>
    </div>
   
    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            <div className="col-lg-4 table-responsive"></div>
            <div className="col-lg-4 table-responsive text-center">
                <p>No Record Found</p>
            </div>
        </div>
    </div>
    </>
    );
  }
  return (
    <>
    <HeaderNavigation page='other' />
    
    <div className="container-fluid bg-secondary mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: "100px"}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3">Shopping Cart</h1>
            <div className="d-inline-flex">
                <p className="m-0"><a href="">Home</a></p>
                <p className="m-0 px-2">-</p>
                <p className="m-0">Shopping Cart</p>
            </div>
        </div>
    </div>
   
    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            <div className="col-lg-8 table-responsive mb-5">
                <table className="table table-bordered text-center mb-0">
                    <thead className="bg-secondary text-dark">
                        <tr>
                            <th>Products</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                    {cartItems.map(item => (
                        <tr key={item.id}>
                            <td className="align-middle"><img src="img/product-1.jpg" alt="" style={{width: "50px"}} /> {item.name}</td>
                            <td className="align-middle">${item.original_price}</td>
                            <td className="align-middle">
                                {item.quantity}
                            </td>
                            <td className="align-middle">${item.sale_price}</td>
                            <td className="align-middle"><button onClick={() => handleDelete(item.id)} className="btn btn-sm btn-primary"><i className="fa fa-times"></i></button></td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="col-lg-4">
                <form className="mb-5">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control p-4"
                            value={couponCode}
                            placeholder="Coupon Code"
                            onChange={(e) => setCouponCode(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-primary" id="applyCoupon" onClick={applyCoupon}>Apply Coupon</button>
                        </div>
                    </div>
                    {couponError ? (
                        <span className="text-danger" style={{ marginTop: '10px', color: 'red', fontSize: '14px' }}>{couponError}</span>
                    ) : (couponApplied) ? (
                        cartData.coupons.map(coupon => (
                            <div key={coupon.id} style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                <span className="text-body" style={{ marginRight: '10px' }}>
                                Coupon code <b>{coupon.name}</b> is applied successfully
                                </span>
                                <button className="btn btn-sm btn-danger" id={coupon.id} onClick={() => clearCoupon(coupon.code)}>
                                    <i className="fa fa-times"></i>  Clear Coupon
                                </button>
                            </div>
                        ))
                        ) : (
                            <span className="text-primary" style={{ marginTop: '10px'}}>No coupons applied</span>
                    )}
                </form>
                <div className="card border-secondary mb-5">
                    <div className="card-header bg-secondary border-0">
                        <h4 className="font-weight-semi-bold m-0">Cart Summary</h4>
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between mb-3 pt-1">
                            <h6 className="font-weight-medium">Subtotal</h6>
                            <h6 className="font-weight-medium">${cartData.base_amount}</h6>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h6 className="font-weight-medium">Discount</h6>
                            <h6 className="font-weight-medium">${cartData.discount_amount}</h6>
                        </div>
                    </div>
                    <div className="card-footer border-secondary bg-transparent">
                        <div className="d-flex justify-content-between mt-2">
                            <h5 className="font-weight-bold">Total</h5>
                            <h5 className="font-weight-bold">${cartData.cart_amount}</h5>
                        </div>
                        <a href="/checkout" className='btn btn-block btn-primary my-3 py-3'>Proceed To Checkout</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default CartListing;