import React, { useEffect, useState } from 'react';

function ProductSearch({ onSearch }) {
    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = (event) => {
        event.preventDefault();
        onSearch(searchQuery); // Pass the search query to the parent component
    };

  return (
    <form onSubmit={handleSearch}>
        <div className="input-group">
        <input
            type="text"
            className="form-control"
            placeholder="Search for products"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="input-group-append">
            <button type="submit" className="btn btn-primary">
            <i className="fa fa-search"></i>
            </button>
        </div>
        </div>
    </form>
  );
};

export default ProductSearch;